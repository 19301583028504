import React from "react"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import track from "../../../components/track"
import heroSafety from "../../../images/hero-hcp-safety.png"
import heroSafetyLarge from "../../../images/hero-hcp-safety-large.png"
import heroSafetyMobile from "../../../images/hero-hcp-safety-mobile.png"
import { savingsTable } from "../../../data/main.json";

const Safety = () => {

  const pageTitle = 'Safety';
  const pageDescription = 'View the safety profile demonstrated across MOVANTIK clinical trials. Curious about side effects and adverse events?';
  const pageName = "Safety";

  const createMarkUp = (str) => {
    return { __html: str };
  };

  const SafetyTable = () => savingsTable.map((savingsTableRow) => (
    <div className="table-row" key={savingsTableRow.id}>
      <div className="table-column column1" dangerouslySetInnerHTML={createMarkUp(savingsTableRow.columnOne)}></div>
      <div className="table-column column2" dangerouslySetInnerHTML={createMarkUp(savingsTableRow.columnTwo)}></div>
      <div className="table-column column3" dangerouslySetInnerHTML={createMarkUp(savingsTableRow.columnThree)}></div>
      <div className="table-column column4" dangerouslySetInnerHTML={createMarkUp(savingsTableRow.columnFour)}></div>
    </div>
  ));

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="hcp" pageSection="hcp-safety" pageClass="safety" pageName={pageName}>
      <div className="page-title">
        <div className="container">Safety</div>
      </div>
      <div className="hero">
        <img src={heroSafetyLarge} className="hero-img large" alt="" />
        <img src={heroSafety} className="hero-img medium" alt="" />
        <img src={heroSafetyMobile} className="hero-img mobile" alt="" />
        <div className="container">
          <div className="headlines">
            <h1>Well-established safety profile</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <h3>MOVANTIK has been studied in 1497 patients<sup>1-3</sup></h3>
        <p>Adverse reactions in <span className="nowrap">KODIAC-04</span> and <span className="nowrap">KODIAC-05</span> that occurred in &ge;3% of patients receiving MOVANTIK 12.5&nbsp;mg or 25&nbsp;mg and at an incidence greater than placebo<sup>1</sup>:</p>
        <div className="safety-table">
          <div className="table-row header-row">
            <div className="table-column column1">&nbsp;</div>
            <div className="table-column column2"><span className="bold">MOVANTIK 25&nbsp;mg</span><br />(n=446)</div>
            <div className="table-column column3"><span className="bold">MOVANTIK 12.5&nbsp;mg</span><br />(n=441)</div>
            <div className="table-column column4"><span className="bold">Placebo</span><br />(n=444)</div>
          </div>
          <SafetyTable />
        </div>
        <p>Safety data for KODIAC-07<sup>†</sup> and KODIAC-08<sup>‡</sup> were similar to those observed in KODIAC-04 and KODIAC-05.<sup>1-3</sup></p>

        <div className="center">
          <Link to="/hcp/contact/" id="hcp-safety-btn-request" className="button" onClick={() => track("HCP Safety page", "button click", "HCP Safety-Get in touch Button")}>
            <div className="btn-text">Ready to learn more?<br /><span className="bold">Contact us</span></div>
            <div className="btn-arrow"></div>
          </Link>
        </div>
        <div className="end-matter">
          <div className="footnotes">
          <div className="footnote asterisk">*Includes: abdominal pain, abdominal pain upper, abdominal pain lower, and gastrointestinal pain.<sup>1</sup></div>

            <div className="footnote"><sup>&dagger;</sup>12-week extension study of KODIAC-04 (n=302).<sup>1,2</sup></div>
            <div className="footnote"><sup>&Dagger;</sup>52-week, phase 3, open-label study of MOVANTIK vs usual care (n=844).<sup>1,3</sup></div>
          </div>
          <div className="references"><span className="bold">Reference: 1.</span>&nbsp;MOVANTIK<sup>&reg;</sup> (naloxegol). Prescribing information. Valinor Pharma, LLC; 2023. <span className="bold">2.</span> Webster L, Tummala R, Diva U, Lappalainen J. A 12-week extension study to assess the safety and tolerability of naloxegol in patients with noncancer pain and opioid-induced constipation. <em>J Opioid Manag</em>. 2016;12(6):405-419. <span className="bold">3.</span> Webster L, Chey WD, Tack J, Lappalainen J, Diva U, Sostek M. Randomised clinical trial: the long-term safety and tolerability of naloxegol in patients with pain and opioid-induced constipation. <em>Aliment Pharmacol Ther</em>. 2014;40(7):771-779.</div>

        </div>
      </div>
    </Layout>
  )
}

export default Safety
